import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Configure max listeners based on codebase analysis
// Current count: 12 listeners (Modal: 7, MediaModal: 3, Navbar: 2)
// Setting to 20 to provide buffer for dynamic listeners
require('events').EventEmitter.defaultMaxListeners = 20;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
