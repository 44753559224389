import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ModalCard {
  readonly title: string;
  readonly content: string | readonly string[];
}

interface ModalContextType {
  isOpen: boolean;
  title: string;
  content: string | React.ReactNode | readonly ModalCard[];
  videos?: ReadonlyArray<{
    readonly id: string;
    readonly thumbnailUrl: string;
    readonly title: string;
    readonly youtubeId?: string;
    readonly kaltura?: string;
  }>;
  openModal: (
    title: string,
    content: string | React.ReactNode | readonly ModalCard[],
    videos?: ReadonlyArray<{
      readonly id: string;
      readonly thumbnailUrl: string;
      readonly title: string;
      readonly youtubeId?: string;
      readonly kaltura?: string;
    }>
  ) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState<string | React.ReactNode | readonly ModalCard[]>('');
  const [videos, setVideos] = useState<ReadonlyArray<{
    readonly id: string;
    readonly thumbnailUrl: string;
    readonly title: string;
    readonly youtubeId?: string;
    readonly kaltura?: string;
  }>>([]);

  const openModal = (
    title: string,
    content: string | React.ReactNode | readonly ModalCard[],
    videos?: ReadonlyArray<{
      readonly id: string;
      readonly thumbnailUrl: string;
      readonly title: string;
      readonly youtubeId?: string;
      readonly kaltura?: string;
    }>
  ) => {
    setIsOpen(true);
    setTitle(title);
    setContent(content);
    if (videos) {
      setVideos(videos);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setTitle('');
    setContent('');
    setVideos([]);
  };

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        title,
        content,
        videos,
        openModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
