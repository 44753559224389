import React from 'react';

interface DesktopMenuProps {
}

const DesktopMenu: React.FC<DesktopMenuProps> = () => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>, id: string) => {
    e.preventDefault();
    const element = document.getElementById(id);
    const container = document.querySelector('.overflow-y-auto');
    if (element && container) {
      const navHeight = document.querySelector('nav')?.offsetHeight || 0;
      const elementPosition = element.getBoundingClientRect().top + container.scrollTop;
      const offset = id === 'about' || id === 'solutions' ? navHeight + 24 : navHeight;
      container.scrollTo({
        top: elementPosition - offset,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="hidden nav:flex items-center space-x-3 nav:space-x-4 lg:space-x-8">
      <a 
        href="#about" 
        onClick={(e) => handleClick(e, 'about')}
        className="text-lg nav:text-xl lg:text-xl font-medium text-[#2A3A55] hover:text-indigo-600 transition-colors font-[Outfit]"
      >
        About
      </a>
      <a 
        href="#solutions" 
        onClick={(e) => handleClick(e, 'solutions')}
        className="text-lg nav:text-xl lg:text-xl font-medium text-[#2A3A55] hover:text-indigo-600 transition-colors font-[Outfit]"
      >
        Solutions
      </a>
      <a 
        href="#contact" 
        onClick={(e) => handleClick(e, 'contact')}
        className="text-lg nav:text-xl lg:text-xl font-medium text-[#2A3A55] hover:text-indigo-600 transition-colors font-[Outfit]"
      >
        Contact
      </a>
      <a 
        href="#memberships" 
        onClick={(e) => handleClick(e, 'memberships')}
        className="text-lg nav:text-xl lg:text-xl font-medium text-[#2A3A55] hover:text-indigo-600 transition-colors font-[Outfit] whitespace-nowrap"
      >
        Memberships/Certifications
      </a>
    </div>
  );
};

export default DesktopMenu;
