import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Footer: React.FC = () => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    // Removed triggerOnce to allow animation to repeat
  });

  const containerVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.footer 
      ref={ref}
      variants={containerVariants}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      className="border-t border-gray-100 py-12 mt-auto"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-center items-center">
          <motion.div 
            className="text-gray-600 text-xs sm:text-sm md:text-lg text-center"
          >
            &copy; {new Date().getFullYear()} SanJae Educational Resources, Inc. All rights reserved.
          </motion.div>
        </div>
      </div>
    </motion.footer>
  );
};

export default Footer;
