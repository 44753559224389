import React from 'react';

interface MobileMenuButtonProps {
  isOpen: boolean;
  onClick: () => void;
  isScrolled: boolean;
}

const MobileMenuButton: React.FC<MobileMenuButtonProps> = ({ isOpen, onClick, isScrolled }) => {
  return (
    <div className="nav:hidden relative -right-2 sm:-right-4 lg:-right-6 top-0">
      <div 
        className={`transition-all duration-300 ease-in-out ${
          isScrolled 
            ? 'h-[8vh] w-[8vh] mobile-landscape:h-[10vh] mobile-landscape:w-[10vh] md:h-[8vh] md:w-[8vh]' 
            : 'h-[12vh] w-[12vh] mobile-landscape:h-[15vh] mobile-landscape:w-[15vh] md:h-[12vh] md:w-[12vh]'
        }`}
      >
        <button
          onClick={onClick}
          className={`w-full h-full flex items-center justify-center focus:outline-none active:bg-gray-100 transition-all duration-300 ease-in-out ${
            isOpen ? 'bg-gray-100' : 'bg-white hover:bg-gray-50'
          }`}
          style={{
            boxShadow: isOpen ? 'none' : '-4px 0 6px -1px rgba(0, 0, 0, 0.1), -2px 0 4px -1px rgba(0, 0, 0, 0.06)',
            WebkitTapHighlightColor: 'transparent'
          }}
          aria-label={isOpen ? 'Close menu' : 'Open menu'}
        >
          <div 
            className="relative w-[24px] h-[24px] transition-all duration-300 ease-in-out"
          >
            {/* Bar 1 */}
            <span
              className={`absolute left-0 right-0 mx-auto h-[2px] bg-gray-600 transform transition-all duration-300 ease-in-out ${
                isOpen ? 'top-1/2 -translate-y-1/2 rotate-45' : 'top-[4px]'
              }`}
              style={{
                width: '24px'
              }}
            />
            {/* Bar 2 */}
            <span
              className={`absolute left-0 right-0 mx-auto top-1/2 -translate-y-1/2 h-[2px] bg-gray-600 transform transition-all duration-300 ease-in-out ${
                isOpen ? 'opacity-0' : 'opacity-100'
              }`}
              style={{
                width: '24px'
              }}
            />
            {/* Bar 3 */}
            <span
              className={`absolute left-0 right-0 mx-auto h-[2px] bg-gray-600 transform transition-all duration-300 ease-in-out ${
                isOpen ? 'bottom-1/2 translate-y-1/2 -rotate-45' : 'bottom-[4px]'
              }`}
              style={{
                width: '24px'
              }}
            />
          </div>
        </button>
      </div>
    </div>
  );
};

export default MobileMenuButton;
