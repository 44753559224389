import React from 'react';

interface LogoProps {
  isScrolled: boolean;
}

const Logo: React.FC<LogoProps> = ({ isScrolled }) => {
  return (
    <div 
      onClick={() => {
        const container = document.querySelector('.overflow-y-auto');
        if (container) {
          container.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
      }}
      className="hover:opacity-80 transition-opacity cursor-pointer"
    >
      <div className="flex items-center space-x-2 nav:space-x-3 lg:space-x-4">
        <img 
          src="/img/sanjae_logo.svg"
          alt="SanJae Logo"
          className={`transition-all duration-300 ease-in-out ${
            isScrolled 
              ? 'h-[6vh] mobile-landscape:h-[8vh] md:h-[7.5vh] lg:h-[8vh]' 
              : 'h-[8vh] mobile-landscape:h-[10vh] md:h-[9.5vh] lg:h-[10vh]'
          }`}
        />
        <div className="flex flex-col -space-y-[3px]">
          <span 
            className={`text-[#661124] font-bold leading-none font-[Outfit] transition-all duration-300 ease-in-out ${
              isScrolled 
                ? 'text-[3vh] mobile-landscape:text-[4vh] md:text-[4vh] lg:text-[4.25vh]' 
                : 'text-[3.5vh] mobile-landscape:text-[5vh] md:text-[4.5vh] lg:text-[4.75vh]'
            }`}
          >
            SanJae
          </span>
          <span 
            className={`text-[#2A3A55] font-bold leading-none font-[Outfit] tracking-wide whitespace-nowrap transition-all duration-300 ease-in-out ${
              isScrolled 
                ? 'text-[1.5vh] mobile-landscape:text-[2vh] md:text-[2vh] lg:text-[2.15vh]' 
                : 'text-[1.75vh] mobile-landscape:text-[2.5vh] md:text-[2.25vh] lg:text-[2.4vh]'
            }`}
          >
            Educational Resources, Inc.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Logo;
