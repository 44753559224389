import React, { useState, useEffect } from 'react';
import Logo from './components/Logo';
import DesktopMenu from './components/DesktopMenu';
import MobileMenu from './components/MobileMenu';
import MobileMenuButton from './components/MobileMenuButton';

const Navbar: React.FC = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Close menus when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.nav-menu') && !target.closest('button')) {
        setMobileMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <nav 
      className={`fixed top-0 left-0 right-0 w-full bg-white z-40 transition-all duration-300 ease-in-out ${
        isScrolled 
          ? 'h-[8vh] mobile-landscape:h-[10vh] md:h-[8vh]' 
          : 'h-[12vh] mobile-landscape:h-[15vh] md:h-[12vh]'
      }`}
      style={{ 
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
      }}
    >
      <div className="w-full h-full">
        <div className="w-full h-full px-4 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between w-full h-full">
            <div className={`flex-shrink-0 transition-transform duration-300 ease-in-out max-w-[60%] lg:max-w-none ${
              isScrolled ? 'scale-90' : 'scale-100'
            }`}>
              <Logo isScrolled={isScrolled} />
            </div>
            <DesktopMenu />
            <MobileMenuButton 
              isOpen={isMobileMenuOpen}
              onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
              isScrolled={isScrolled}
            />
          </div>
        </div>
      </div>
      <MobileMenu 
        isOpen={isMobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        isScrolled={isScrolled}
      />
    </nav>
  );
};

export default Navbar;
