import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const About: React.FC = () => {
  const { ref, inView } = useInView({
    threshold: 0.05,
    rootMargin: '-10% 0px -10% 0px',
    triggerOnce: true
  });

  const containerVariants = {
    hidden: {
      scale: 1,
      y: 30,
      opacity: 0
    },
    visible: {
      scale: 1,
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  return (
    <section id="about" className="py-16 md:py-20 lg:py-24 bg-white">
      <motion.div 
        ref={ref}
        variants={containerVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <div className="max-w-3xl mx-auto">
          <div className="space-y-6">
            <div>
              <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold text-[#1B3168] font-[Outfit]">
                <span className="inline-block">
                  <span className="relative inline-block">
                    About
                    <div className="absolute bottom-0 left-0 w-full h-1 bg-indigo-600 rounded -mb-2"></div>
                  </span>
                </span>
                <span className="block mt-2">SanJae Educational Resources</span>
              </h2>
            </div>
            <p className="text-lg md:text-xl lg:text-2xl text-gray-600 leading-relaxed font-[Inter]">
              SanJae Educational Resources, Inc. is an educational technology solutions provider based in South Florida. 
              Our mission is to empower educators and institutions with resources that enhance learning outcomes and 
              foster student success. We combine decades of educational expertise with technological solutions to create meaningful impact for learners of all ages.
            </p>
            <p className="text-lg md:text-xl lg:text-2xl text-gray-600 leading-relaxed font-[Inter]">
              Our product line includes AR/XR/VR, AI and other technology-based teaching and training tools. Established in 1999, SanJae has successfully deployed innovative learning solutions in various settings, including public schools, colleges, prisons, career and vocational education agencies, and adult education facilities across the United States.
            </p>
            <p className="text-lg md:text-xl lg:text-2xl text-gray-600 leading-relaxed font-[Inter]">
              SanJae Educational Resources is dedicated to advancing education through cutting-edge digital tools and resources.
            </p>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default About;
