import React from 'react';
import Navbar from './components/Navbar/Navbar';
import Hero from './components/Hero/Hero';
import About from './components/About/About';
import Solutions from './components/Solutions/Solutions';
import Contact from './components/Contact/Contact';
import Memberships from './components/Memberships/Memberships';
import Modal from './components/Modal/Modal';
import Footer from './components/Footer/Footer';
import { ModalProvider } from './context/ModalContext';
import './styles/modal.css';

const AppContent: React.FC = () => {
  return (
    <div className="fixed inset-0 w-full overflow-y-auto overflow-x-hidden bg-white font-[Inter]">
      <div className="min-h-screen w-full relative">
        <Navbar />
        <div className="w-full pt-[16vh] mobile-landscape:pt-[20vh] md:pt-[16vh] pb-16">
          <Hero />
          <About />
          <Solutions />
          <Contact />
          <Memberships />
        </div>
        <Footer />
        <Modal />
      </div>
    </div>
  );
};

function App() {
  return (
    <ModalProvider>
      <AppContent />
    </ModalProvider>
  );
}

export default App;
