import React, { useState, useEffect, useRef } from 'react';
import { useModal } from '../../context/ModalContext';
import MediaModal from './MediaModal';
import { MODAL_CONTENT } from '../../constants/modalContent';

interface ModalCard {
  readonly title: string;
  readonly content: string | readonly string[];
}

const Modal: React.FC = () => {
  const { isOpen, title, content, videos, closeModal } = useModal();
  const modalData = Object.values(MODAL_CONTENT).find(modal => modal.title === title);
  const [selectedMedia, setSelectedMedia] = useState<{ id: string; type: 'video' | 'image'; index: number } | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [canScroll, setCanScroll] = useState({ left: false, right: false });
  const [isAnimating, setIsAnimating] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const videosPerView = 4;
  const carouselRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [scrollInfo, setScrollInfo] = useState({ scrollTop: 0, scrollHeight: 0, clientHeight: 0 });

  useEffect(() => {
    if (isOpen) {
      // Save current scroll position
      const scrollPos = window.scrollY;
      setIsAnimating(true);
      // Small delay to ensure mount animation works
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          setIsVisible(true);
          // Restore scroll position
          window.scrollTo(0, scrollPos);
        });
      });
    } else {
      setIsVisible(false);
      const timer = setTimeout(() => {
        setIsAnimating(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen && !selectedMedia) {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [isOpen, selectedMedia, closeModal]);

  // Handle content scroll
  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      setScrollInfo({ scrollTop, scrollHeight, clientHeight });
    }
  };

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial calculation
    }
    return () => {
      if (contentElement) {
        contentElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isOpen]);

  // Calculate thumb position and height
  const getScrollThumbStyle = () => {
    const { scrollHeight, clientHeight } = scrollInfo;
    const scrollTrackHeight = clientHeight;
    const scrollThumbHeight = Math.max(
      (clientHeight / scrollHeight) * scrollTrackHeight,
      40
    );
    const scrollThumbPosition =
      (scrollInfo.scrollTop / (scrollHeight - clientHeight)) *
      (scrollTrackHeight - scrollThumbHeight);

    return {
      height: `${scrollThumbHeight}px`,
      transform: `translateY(${scrollThumbPosition}px)`,
    };
  };

  // Update scroll state and check if thumbnails should be centered
  const updateScrollState = () => {
    const carousel = carouselRef.current;
    if (!carousel || !videos) return;

    // Get scroll metrics
    const scrollLeft = Math.round(carousel.scrollLeft);
    const scrollWidth = Math.round(carousel.scrollWidth);
    const clientWidth = Math.round(carousel.clientWidth);

    // Calculate total width needed for all thumbnails
    const thumbnailWidth = carousel.querySelector('.thumbnail-item')?.clientWidth || 0;
    
    // Get computed gap from styles
    const computedStyle = window.getComputedStyle(carousel);
    const gap = parseInt(computedStyle.gap) || 0;
    
    const totalThumbnailsWidth = (thumbnailWidth * videos.length) + (gap * (videos.length - 1));

    // Check if we can scroll in either direction
    const hasLeftScroll = scrollLeft > 0;
    const hasRightScroll = scrollLeft + clientWidth < scrollWidth;

    // Apply centering class if carousel width can fit all thumbnails
    carousel.style.justifyContent = clientWidth >= totalThumbnailsWidth ? 'center' : 'flex-start';

    setCanScroll({
      left: hasLeftScroll,
      right: hasRightScroll
    });
  };

  // Handle scroll events
  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel || !videos) return;

    const handleScroll = () => {
      requestAnimationFrame(updateScrollState);
    };

    carousel.addEventListener('scroll', handleScroll, { passive: true });
    
    // Initial check
    updateScrollState();

    // Add resize observer to handle width changes
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateScrollState);
    });
    resizeObserver.observe(carousel);

    return () => {
      carousel.removeEventListener('scroll', handleScroll);
      resizeObserver.disconnect();
    };
  }, [videos]);

  // Update on resize
  useEffect(() => {
    const handleResize = () => {
      updateScrollState();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Reset scroll when modal opens or videos change
  useEffect(() => {
    if (isOpen && carouselRef.current) {
      carouselRef.current.scrollLeft = 0;
      requestAnimationFrame(updateScrollState);
    }
  }, [isOpen, videos]);

  const handleMediaClose = () => {
    setSelectedMedia(null);
  };

  const handleMediaClick = (id: string) => {
    const mediaType = id.includes('mathmedia/') ? 'image' : 'video';
    const currentIndex = videos?.findIndex(v => v.id === id) ?? -1;
    setSelectedMedia({ id, type: mediaType, index: currentIndex });
  };

  const handleMediaNext = () => {
    if (!selectedMedia || !videos || selectedMedia.index === undefined) return;
    const nextIndex = selectedMedia.index + 1;
    if (nextIndex < videos.length) {
      const nextVideo = videos[nextIndex];
      const mediaType = nextVideo.id.includes('mathmedia/') ? 'image' : 'video';
      setSelectedMedia({ id: nextVideo.id, type: mediaType, index: nextIndex });
    }
  };

  const handleMediaPrevious = () => {
    if (!selectedMedia || !videos || selectedMedia.index === undefined) return;
    const prevIndex = selectedMedia.index - 1;
    if (prevIndex >= 0) {
      const prevVideo = videos[prevIndex];
      const mediaType = prevVideo.id.includes('mathmedia/') ? 'image' : 'video';
      setSelectedMedia({ id: prevVideo.id, type: mediaType, index: prevIndex });
    }
  };

  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const handlePrevClick = () => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    const scrollAmount = carousel.clientWidth;
    carousel.scrollBy({
      left: -scrollAmount,
      behavior: 'smooth'
    });
  };

  const handleNextClick = () => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    const scrollAmount = carousel.clientWidth;
    carousel.scrollBy({
      left: scrollAmount,
      behavior: 'smooth'
    });
  };

  // Touch handling
  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    let startX: number;
    let currentX: number;
    let isDragging = false;

    const handleTouchStart = (e: TouchEvent) => {
      startX = e.touches[0].clientX;
      currentX = startX;
      isDragging = true;
    };

    const handleTouchMove = (e: TouchEvent) => {
      if (!isDragging) return;
      
      const touchX = e.touches[0].clientX;
      const diff = currentX - touchX;
      currentX = touchX;
      
      carousel.scrollLeft += diff;
    };

    const handleTouchEnd = () => {
      isDragging = false;
      updateScrollState();
    };

    carousel.addEventListener('touchstart', handleTouchStart);
    carousel.addEventListener('touchmove', handleTouchMove);
    carousel.addEventListener('touchend', handleTouchEnd);

    return () => {
      carousel.removeEventListener('touchstart', handleTouchStart);
      carousel.removeEventListener('touchmove', handleTouchMove);
      carousel.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  const handleWheel = (e: React.WheelEvent) => {
    e.stopPropagation();
  };

  if (!isOpen && !isAnimating) return null;

  return (
    <>
      <div 
        className={`modal ${isVisible ? 'open' : ''}`}
        onClick={handleOverlayClick}
      >
        <div 
          className="modal-content bg-white rounded-lg shadow-xl border border-gray-200 p-4"
          onWheel={handleWheel}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="h-[9%] border-b border-gray-200 flex items-center justify-center px-4">
            <div className="flex justify-between items-center w-full h-full">
              <a 
                href={modalData?.websiteUrl} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="flex items-center gap-3 hover:opacity-80 transition-opacity"
              >
                {(() => {
                  const logoMap: Record<string, string> = {
                    "NYC English": "nyc_english.jpg",
                    "Money Vehicle": "money_vehicle.png",
                    "CW Publications": "cwpub.jpg",
                    "Techworks 4 Good": "techworks4good.png",
                    "CG1 Solutions": "cg1solutions.png",
                    "MathMedia": "mm.jpg",
                    "Instructional Systems": "isi.jpg",
                    "Films Media Group": "fmg.jpg"
                  };
                  
                  const logoFile = logoMap[title];
                  return logoFile ? (
                    <img
                      src={`/img/company_logos/${logoFile}`}
                      alt={`${title} Logo`}
                      className="h-8 w-auto object-contain"
                    />
                  ) : null;
                })()}
                <h2 className="text-base sm:text-lg font-semibold text-[rgb(99,102,241)]">
                  {title}
                  <span className="ml-2 font-normal">Website</span>
                </h2>
              </a>
              <button
                onClick={closeModal}
                className="text-gray-400 hover:text-gray-500"
              >
                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div 
            className="h-[61%] flex"
          >
            <div className="flex-1 relative">
              <div
                ref={contentRef}
                className="h-full overflow-y-scroll pr-4 overscroll-contain hide-scrollbar pt-4 pb-4"
              >
                {Array.isArray(content) && content.every((item): item is ModalCard => 
                  'title' in item && 'content' in item
                ) ? (
                  <div className="w-full pl-8 pr-5 md:pl-10 md:pr-7 lg:pl-12 lg:pr-9">
                    <div 
                      className="grid gap-6 w-full"
                      style={{
                        gridTemplateColumns: 'repeat(auto-fit, minmax(min(100%, 32rem), 32rem))',
                        justifyContent: 'center'
                      }}
                    >
                      {content.map((card, index) => (
                        <div 
                          key={index} 
                          className={`
                            p-6 rounded-xl transition-all duration-300 hover:shadow-lg w-full
                            ${index % 3 === 0 ? 'bg-gradient-to-br from-indigo-50 to-white border border-indigo-100' : 
                              index % 3 === 1 ? 'bg-gradient-to-br from-blue-50 to-white border border-blue-100' :
                              'bg-gradient-to-br from-purple-50 to-white border border-purple-100'}
                          `}
                        >
                          <h3 className="text-lg font-semibold mb-3 text-gray-900">{card.title}</h3>
                          {Array.isArray(card.content) ? (
                            <ul className="list-disc list-inside space-y-2">
                              {card.content.map((item: string, idx: number) => (
                                <li key={idx} className="text-base leading-relaxed text-gray-700">{item}</li>
                              ))}
                            </ul>
                          ) : (
                            <p className="text-base leading-relaxed text-gray-700">
                              {card.content}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : typeof content === 'string' ? (
                  <div className="w-full pl-8 pr-5 md:pl-10 md:pr-7 lg:pl-12 lg:pr-9">
                    <div 
                      className="grid gap-6 w-full"
                      style={{
                        gridTemplateColumns: 'repeat(auto-fit, minmax(min(100%, 32rem), 32rem))',
                        justifyContent: 'center'
                      }}
                    >
                      {content.split('\n\n').map((paragraph, index) => (
                        <div 
                          key={index} 
                          className={`
                            p-6 rounded-xl transition-all duration-300 hover:shadow-lg w-full
                            ${index % 3 === 0 ? 'bg-gradient-to-br from-indigo-50 to-white border border-indigo-100' : 
                              index % 3 === 1 ? 'bg-gradient-to-br from-blue-50 to-white border border-blue-100' :
                              'bg-gradient-to-br from-purple-50 to-white border border-purple-100'}
                          `}
                        >
                          <p className="text-base leading-relaxed text-gray-700">
                            {paragraph}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="text-sm leading-relaxed text-gray-600 whitespace-pre-wrap pl-8 pr-5 md:pl-10 md:pr-7 lg:pl-12 lg:pr-9">
                    {content as React.ReactNode}
                  </div>
                )}
              </div>
              <div className="absolute right-0 top-0 bottom-0 w-2 bg-gray-100 rounded">
                <div
                  className="w-full bg-gray-400 rounded hover:bg-gray-500 transition-colors cursor-pointer"
                  style={getScrollThumbStyle()}
                />
              </div>
            </div>
          </div>

          {videos && videos.length > 0 && (
            <div className="h-[35%] min-h-0 border-t border-gray-200 overflow-hidden">
              <div className="carousel-container">
                <div className="carousel-nav-container">
                  {canScroll.left && (
                    <button
                      className="carousel-nav carousel-prev"
                      onClick={handlePrevClick}
                      aria-label="Previous"
                    >
                      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                      </svg>
                    </button>
                  )}
                </div>

                <div
                  ref={carouselRef}
                  className="thumbnail-carousel"
                >
                  {videos.map((video, index) => (
                    <div
                      key={index}
                      className="thumbnail-item"
                    >
                      <div
                        className="relative cursor-pointer group"
                        onClick={() => handleMediaClick(video.id)}
                      >
                        <div className="w-full h-full rounded-lg overflow-hidden relative bg-gray-100">
                          {video.thumbnailUrl ? (
                            <img
                              src={video.thumbnailUrl}
                              alt={video.title}
                              className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                            />
                          ) : (
                            <div className="w-full h-full flex items-center justify-center">
                              <span className="text-gray-500 text-sm">{video.title}</span>
                            </div>
                          )}
                          {video.id && (
                            <>
                              <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                {!video.id.includes('mathmedia/') && (
                                  <svg
                                    className="w-12 h-12 text-white mb-2"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                  >
                                    <path d="M8 5v10l7-5-7-5z" />
                                  </svg>
                                )}
                              </div>
                              <div className="thumbnail-title">
                                {video.title}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="carousel-nav-container">
                  {canScroll.right && (
                    <button
                      className="carousel-nav carousel-next"
                      onClick={handleNextClick}
                      aria-label="Next"
                    >
                      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      
      <MediaModal
        isOpen={!!selectedMedia}
        onClose={handleMediaClose}
        mediaId={selectedMedia?.id || ''}
        mediaType={selectedMedia?.type || 'video'}
        onPrevious={selectedMedia?.index !== undefined && selectedMedia.index > 0 ? handleMediaPrevious : undefined}
        onNext={selectedMedia?.index !== undefined && videos && selectedMedia.index < videos.length - 1 ? handleMediaNext : undefined}
        hasPrevious={selectedMedia?.index !== undefined && selectedMedia.index > 0}
        hasNext={selectedMedia?.index !== undefined && videos && selectedMedia.index < videos.length - 1}
      />
    </>
  );
};

export default Modal;
