import React from 'react';

interface MobileMenuProps {
  isOpen: boolean;
  isScrolled: boolean;
  onClose?: () => void;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, isScrolled, onClose }) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>, id: string) => {
    e.preventDefault();
    const element = document.getElementById(id);
    const container = document.querySelector('.overflow-y-auto');
    if (element && container) {
      const navHeight = document.querySelector('nav')?.offsetHeight || 0;
      const elementPosition = element.getBoundingClientRect().top + container.scrollTop;
      const offset = id === 'about' || id === 'solutions' ? navHeight + 24 : navHeight;
      container.scrollTo({
        top: elementPosition - offset,
        behavior: 'smooth'
      });
      onClose?.();
    }
  };

  return (
    <div 
      className={`${
        isOpen ? 'translate-y-0 opacity-100 pointer-events-auto' : '-translate-y-4 opacity-0 pointer-events-none'
      } fixed left-0 right-0 w-full bg-white shadow-lg border-t border-gray-100 transition-all duration-300 ease-in-out z-30 transform ${
        isScrolled 
          ? 'top-[8vh] mobile-landscape:top-[10vh] md:top-[8vh]' 
          : 'top-[12vh] mobile-landscape:top-[15vh] md:top-[12vh]'
      }`}
      style={{
        transitionProperty: 'transform, opacity',
        willChange: 'transform, opacity'
      }}
    >
      <div className={`px-4 pt-3 pb-4 space-y-2 transition-all duration-300 ease-in-out ${
        isScrolled ? 'scale-95' : 'scale-100'
      }`}>
        <a 
          href="#about" 
          onClick={(e) => handleClick(e, 'about')}
          className={`block px-4 py-3 rounded-md font-medium text-[#2A3A55] hover:text-indigo-600 hover:bg-gray-50 transition-all duration-300 ease-in-out font-[Outfit] ${
            isScrolled ? 'text-lg' : 'text-xl'
          }`}
        >
          About
        </a>
        <a 
          href="#solutions" 
          onClick={(e) => handleClick(e, 'solutions')}
          className={`block px-4 py-3 rounded-md font-medium text-[#2A3A55] hover:text-indigo-600 hover:bg-gray-50 transition-all duration-300 ease-in-out font-[Outfit] ${
            isScrolled ? 'text-lg' : 'text-xl'
          }`}
        >
          Solutions
        </a>
        <a 
          href="#contact" 
          onClick={(e) => handleClick(e, 'contact')}
          className={`block px-4 py-3 rounded-md font-medium text-[#2A3A55] hover:text-indigo-600 hover:bg-gray-50 transition-all duration-300 ease-in-out font-[Outfit] ${
            isScrolled ? 'text-lg' : 'text-xl'
          }`}
        >
          Contact
        </a>
        <a 
          href="#memberships" 
          onClick={(e) => handleClick(e, 'memberships')}
          className={`block px-4 py-3 rounded-md font-medium text-[#2A3A55] hover:text-indigo-600 hover:bg-gray-50 transition-all duration-300 ease-in-out font-[Outfit] ${
            isScrolled ? 'text-lg' : 'text-xl'
          }`}
        >
          Memberships/Certifications
        </a>
      </div>
    </div>
  );
};

export default MobileMenu;
