import React, { useEffect, useState, useRef } from 'react';

interface MediaModalProps {
  isOpen: boolean;
  onClose: () => void;
  mediaId: string;
  mediaType: 'video' | 'image';
  onPrevious?: () => void;
  onNext?: () => void;
  hasPrevious?: boolean;
  hasNext?: boolean;
}

const MediaModal: React.FC<MediaModalProps> = ({ 
  isOpen, 
  onClose, 
  mediaId, 
  mediaType,
  onPrevious,
  onNext,
  hasPrevious,
  hasNext
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const modalContentRef = useRef<HTMLDivElement>(null);
  const [modalDimensions, setModalDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
      // Small delay to ensure mount animation works
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          setIsVisible(true);
        });
      });
    } else {
      setIsVisible(false);
      const timer = setTimeout(() => {
        setIsAnimating(false);
      }, 300); // Match this with CSS transition duration
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const getMinDimensions = () => {
    // Tailwind breakpoints: sm: 640px, md: 768px, lg: 1024px
    const width = window.innerWidth;
    if (width >= 1024) { // lg
      return {
        width: Math.max(modalDimensions.width * 0.6, 400),
        height: Math.max(modalDimensions.height * 0.6, 400)
      };
    } else if (width >= 768) { // md
      return {
        width: Math.max(modalDimensions.width * 0.5, 350),
        height: Math.max(modalDimensions.height * 0.5, 350)
      };
    } else { // mobile
      return {
        width: Math.max(modalDimensions.width * 0.45, 300),
        height: Math.max(modalDimensions.height * 0.45, 300)
      };
    }
  };

  useEffect(() => {
    const updateDimensions = () => {
      if (modalContentRef.current) {
        const { clientWidth, clientHeight } = modalContentRef.current;
        setModalDimensions({ width: clientWidth, height: clientHeight });
      }
    };

    if (isOpen) {
      // Initial update
      updateDimensions();
      // Add resize listener
      window.addEventListener('resize', updateDimensions);
      return () => window.removeEventListener('resize', updateDimensions);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        event.stopPropagation(); // Prevent the main modal from also handling the escape key
        onClose();
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (!isOpen) return;
      
      if (event.key === 'ArrowLeft' && hasPrevious && onPrevious) {
        event.preventDefault();
        onPrevious();
      } else if (event.key === 'ArrowRight' && hasNext && onNext) {
        event.preventDefault();
        onNext();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    document.addEventListener('keydown', handleKeyDown);
    
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose, onPrevious, onNext, hasPrevious, hasNext]);

  if (!isOpen && !isAnimating) return null;

  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const getVideoUrl = (id: string) => {
    if (id.includes('youtube.com') || id.includes('youtu.be')) {
      // Handle full YouTube URLs
      const embedUrl = id.replace('watch?v=', 'embed/');
      return embedUrl;
    } else if (id.length === 11) {
      // Handle YouTube video IDs
      return `https://www.youtube.com/embed/${id}`;
    } else if (id.includes('drive.google.com')) {
      // Handle Google Drive URLs
      return id.replace('/view', '/preview');
    } else if (id.startsWith('1_')) {
      // Handle Kaltura video IDs
      const baseUrl = 'https://cdnapisec.kaltura.com/html5/html5lib/v2.101/mwEmbedFrame.php';
      const partnerId = '1067292';
      const uiconfId = '34527491';
      const referrer = encodeURIComponent(window.location.origin);
      
      return `${baseUrl}/p/${partnerId}/uiconf_id/${uiconfId}/entry_id/${id}?` +
        `wid=_${partnerId}&` +
        `iframeembed=true&` +
        `playerId=pid_divMedia&` +
        `entry_id=${id}&` +
        `flashvars[twoPhaseManifest]=true&` +
        `flashvars[streamerType]=hdnetworkmanifest&` +
        `flashvars[autoPlay]=false&` +
        `flashvars[externalInterfaceDisabled]=false&` +
        `flashvars[maxAllowedRegularBitrate]=1000&` +
        `flashvars[maxAllowedFSBitrate]=1000&` +
        `flashvars[mediaProxy.preferedFlavorBR]=1000&` +
        `flashvars[mediaProxy.autoSwitchFlavors]=true&` +
        `flashvars[disableBitrateCookie]=true&` +
        `flashvars[EmbedPlayer.EnableURLTimeEncoding]=true&` +
        `flashvars[scrubber.showOnlyTime]=true&` +
        `flashvars[playbackRateSelector.plugin]=false&` +
        `flashvars[akamaiHD.loadingPolicy]=preInitialize&` +
        `flashvars[akamaiHD.asyncInit]=true&` +
        `flashvars[controlBarContainer.plugin]=true&` +
        `flashvars[controlBarContainer.hover]=true&` +
        `flashvars[EmbedPlayer.MouseMoveTimeout]=6000&` +
        `flashvars[mediaProxy.mediaPlayFrom]=0&` +
        `flashvars[mediaProxy.mediaPlayTo]=120&` +
        `flashvars[IframeCustomPluginCss1]=/Common/css/kalturaCustomStyles.css&` +
        `flashvars[nativeCallout]=false&` +
        `flashvars[forceMobileHTML5]=true&` +
        `flashvars[EmbedPlayer.EnableMobileSkin]=true&` +
        `flashvars[EmbedPlayer.WebKitPlaysInline]=true&` +
        `flashvars[EmbedPlayer.EnableFullscreen]=true&` +
        `flashvars[referrer]=${referrer}`;
    } else {
      // Handle Google Drive IDs
      return `https://drive.google.com/file/d/${id}/preview`;
    }
  };

  const getImageUrl = (id: string) => {
    // If the path starts with a slash, it's already a full path
    if (id.startsWith('/')) {
      return id;
    }
    // Otherwise, assume it's a carousel image ID
    return `/img/carousel_imgs/${id}`;
  };

  return (
    <div 
      className={`modal ${isVisible ? 'open' : ''}`}
      onClick={handleOverlayClick}
    >
      <div className="modal-content flex items-center justify-center max-h-screen p-4 sm:p-6 md:p-8 landscape:py-6 max-h-[100dvh]" ref={modalContentRef}>
        <button 
          className="absolute top-4 left-4 text-white hover:text-gray-300 transition-colors z-10 p-2 rounded-full bg-black/30 hover:bg-black/40"
          onClick={onClose}
        >
          <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        
        {/* Navigation Buttons */}
        {hasPrevious && onPrevious && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              onPrevious();
            }}
            className="absolute left-4 top-1/2 -translate-y-1/2 text-white hover:text-gray-300 transition-colors z-10 p-2 rounded-full bg-black/30 hover:bg-black/40"
            aria-label="Previous"
          >
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
        )}
        
        {hasNext && onNext && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              onNext();
            }}
            className="absolute right-4 top-1/2 -translate-y-1/2 text-white hover:text-gray-300 transition-colors z-10 p-2 rounded-full bg-black/30 hover:bg-black/40"
            aria-label="Next"
          >
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
            </svg>
          </button>
        )}
        
        <div className="w-full h-full flex items-center justify-center landscape:py-2">
          {mediaType === 'video' ? (
            mediaId.startsWith('1_') ? (
              <iframe
                className="rounded-lg w-full h-full max-h-[calc(100vh-2rem)] landscape:max-h-[calc(100vh-3rem)] md:max-h-[calc(100vh-4rem)]"
                src={getVideoUrl(mediaId)}
                frameBorder="0"
                allowFullScreen
                allow="autoplay; fullscreen; encrypted-media"
                style={{
                  aspectRatio: '16/9',
                  maxWidth: '100%',
                  margin: 'auto',
                  pointerEvents: 'auto'
                }}
              />
            ) : (
              <iframe
                src={getVideoUrl(mediaId)}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="rounded-lg"
              />
            )
          ) : (
            <img
              src={getImageUrl(mediaId)}
              alt="Media content"
              className="w-auto h-auto min-h-[90%] max-h-[90%] portrait:min-h-[50%] portrait:max-h-[50%]"
              //className="w-auto h:200px min-h-[calc(100dvh-6rem)] max-h-[calc(100dvh-6rem)] object-contain object-center rounded-lg"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaModal;
