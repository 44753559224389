import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import MediaModal from '../Modal/MediaModal';

const Memberships: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const { ref, inView } = useInView({
    threshold: 0.05,
    rootMargin: '-10% 0px -10% 0px',
    triggerOnce: true
  });

  // Animation variants for the wave effect
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const titleVariants = {
    hidden: {
      opacity: 0,
      x: 100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  const itemVariants = {
    hidden: {
      opacity: 0,
      x: 100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  const images = [
    {
      src: "/img/coops_certificates/tips-usa-awarded-vendor-square.png",
      alt: "TIPS USA Awarded Vendor"
    },
    {
      src: "/img/coops_certificates/cp_seal.jpg",
      alt: "CP Seal Certification"
    },
    {
      src: "/img/coops_certificates/metro_small_business_enterprise_certified.jpg",
      alt: "Metro Small Business Enterprise Certified"
    },
    {
      src: "/img/coops_certificates/fl_dms_woman_business_certification.jpg",
      alt: "Florida DMS Woman Business Certification"
    }
  ];

  return (
    <>
      <section id="memberships" className="py-12 md:py-16 lg:py-20 bg-white">
        <motion.div 
          ref={ref}
          variants={containerVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
        >
          <motion.h2 
            variants={titleVariants}
            className="text-3xl md:text-4xl lg:text-5xl font-semibold text-center mb-8 md:mb-10 lg:mb-12 text-[#1B3168] font-[Outfit]"
          >
            Cooperative Memberships & Certifications
          </motion.h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 sm:gap-8 lg:gap-10 justify-items-center">
            {images.map((image, index) => (
              <motion.div 
                key={index}
                variants={itemVariants}
                className="w-full max-w-[280px] cursor-pointer"
                onClick={() => setSelectedImage(index)}
              >
                <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 h-full">
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="w-full h-full object-contain"
                  />
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </section>

      <MediaModal
        isOpen={selectedImage !== null}
        onClose={() => setSelectedImage(null)}
        mediaId={selectedImage !== null ? images[selectedImage].src : ''}
        mediaType="image"
        onPrevious={() => setSelectedImage(prev => prev !== null && prev > 0 ? prev - 1 : null)}
        onNext={() => setSelectedImage(prev => prev !== null && prev < images.length - 1 ? prev + 1 : null)}
        hasPrevious={selectedImage !== null && selectedImage > 0}
        hasNext={selectedImage !== null && selectedImage < images.length - 1}
      />
    </>
  );
};

export default Memberships;
