import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Hero: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const img = new window.Image();
    img.src = '/img/FETC-2025.png';
    
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      setImageLoaded(true);

      let pixelSize = 40;
      let frame = 0;
      const totalFrames = 30; 
      let lastFrameTime = 0;
      const frameDelay = 16; 

      const animate = (timestamp: number) => {
        if (frame >= totalFrames) {
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          return;
        }

        if (timestamp - lastFrameTime < frameDelay) {
          requestAnimationFrame(animate);
          return;
        }

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        
        const currentPixelSize = pixelSize * (1 - frame / totalFrames);
        
        const w = canvas.width;
        const h = canvas.height;
        
        ctx.drawImage(img, 0, 0, w, h);
        
        if (currentPixelSize > 1) {
          ctx.imageSmoothingEnabled = false;
          
          const scaledWidth = w / currentPixelSize;
          const scaledHeight = h / currentPixelSize;
          
          const tempCanvas = document.createElement('canvas');
          const tempCtx = tempCanvas.getContext('2d');
          if (!tempCtx) return;
          
          tempCanvas.width = scaledWidth;
          tempCanvas.height = scaledHeight;
          
          tempCtx.drawImage(canvas, 0, 0, scaledWidth, scaledHeight);
          
          ctx.drawImage(
            tempCanvas, 
            0, 0, scaledWidth, scaledHeight,
            0, 0, w, h
          );
        }

        frame++;
        lastFrameTime = timestamp;
        requestAnimationFrame(animate);
      };

      if (inView) {
        frame = 0;
        lastFrameTime = 0;
        requestAnimationFrame(animate);
      }
    };
  }, [inView]);

  // Animation variants for the wave effect
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: {
      opacity: 0,
      x: -100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1.0,
        ease: "easeOut",
      },
    },
  };

  return (
    <section className="relative min-h-[40vh] flex items-center justify-center mobile-landscape:mt-[7.5vh]">
      <div className="absolute inset-0 -z-10 bg-gradient-to-b from-white via-gray-50 to-white"></div>
      <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
        <motion.div 
          ref={ref}
          variants={containerVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          className="text-left max-w-2xl lg:max-w-xl px-4 sm:px-6 lg:px-8 mx-auto lg:ml-auto lg:mr-0"
        >
          <motion.h1 
            variants={itemVariants}
            className="text-2xl sm:text-4xl md:text-5xl font-bold tracking-tight bg-gradient-to-r from-gray-900 via-indigo-800 to-gray-900 bg-clip-text text-transparent pb-2"
          >
            Transforming Education Through Technology
          </motion.h1>
          <motion.p 
            variants={itemVariants}
            className="mt-6 text-base sm:text-lg md:text-xl leading-8 text-gray-600"
          >
            SanJae Educational Resources empowers learners and educators with innovative technological solutions.
          </motion.p>
        </motion.div>
        
        <motion.div
          variants={itemVariants}
          className="flex justify-center items-center px-4 sm:px-6 lg:px-8 mx-auto lg:ml-0 lg:mr-auto"
        >
          <a href="https://www.fetc.org/" target="_blank" rel="noopener noreferrer" className="w-full max-w-lg">
            <motion.div
              className="relative bg-white rounded-lg shadow-lg overflow-hidden"
              initial={{ opacity: 0 }}
              animate={{ opacity: imageLoaded ? 1 : 0 }}
              transition={{ duration: 0.3 }}
            >
              <canvas 
                ref={canvasRef}
                className="w-full h-auto"
              />
            </motion.div>
          </a>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;
