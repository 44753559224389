import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './Contact.module.css';

const FORMSPARK_ACTION_URL = "https://submit-form.com/4OqWd2Ovy";

const Contact: React.FC = () => {
  const titles = ["For More Information", "Contact Us"];
  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  const [isChanging, setIsChanging] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.05,
    rootMargin: '-10% 0px -10% 0px',
    triggerOnce: true
  });
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsChanging(true);
      setTimeout(() => {
        setCurrentTitleIndex((prev) => (prev + 1) % titles.length);
        setIsChanging(false);
      }, 300);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus('idle');

    try {
      // Get reCAPTCHA token
      const token = recaptchaRef.current?.getValue();
      if (!token) {
        throw new Error('Please complete the reCAPTCHA');
      }

      // Get form data
      const formData = new FormData(formRef.current!);
      const formObject = Object.fromEntries(formData.entries());

      // Send to Formspark
      const response = await fetch(FORMSPARK_ACTION_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({
          ...formObject,
          _recaptcha: token,
        }),
      });

      if (!response.ok) {
        throw new Error('Form submission failed');
      }

      // Reset form and show success message
      formRef.current?.reset();
      recaptchaRef.current?.reset();
      setSubmitStatus('success');
    } catch (error) {
      console.error('Form submission error:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="contact" className="py-12 md:py-16 lg:py-20">
      <motion.div 
        ref={inViewRef}
        initial={{ scale: 1.2, y: -50, opacity: 0 }}
        animate={{ 
          scale: inView ? 1 : 1.2,
          y: inView ? 0 : -50,
          opacity: inView ? 1 : 0 
        }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <div className="max-w-3xl mx-auto">
          <h2 className={`text-3xl md:text-4xl lg:text-5xl font-semibold text-center mb-6 md:mb-8 lg:mb-10 font-[Outfit] text-[#1B3168] ${styles.titleTransition} ${isChanging ? styles.fadeOut : styles.fadeIn}`}>
            {titles[currentTitleIndex]}
          </h2>
          <div className="text-center mb-8">
            <div className="inline-block">
              <a href="tel:8777265231" className="text-indigo-600 hover:underline">
                <p className="text-lg md:text-xl lg:text-2xl font-[Inter] mb-0 font-bold transition-colors duration-300">877-726-5231</p>
              </a>
            </div>
          </div>
          <p className="text-lg md:text-xl lg:text-2xl text-center text-gray-600 mb-8 font-[Inter]">
            We'd love to hear from you. Please fill out this form.
          </p>

          <form ref={formRef} onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label 
                  htmlFor="first-name" 
                  className="block text-lg md:text-xl lg:text-2xl font-medium text-gray-700 font-[Inter]"
                >
                  First name
                </label>
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-lg md:text-xl lg:text-2xl px-4 py-3 bg-white hover:border-gray-400 transition-colors"
                  required
                />
              </div>
              <div>
                <label 
                  htmlFor="last-name" 
                  className="block text-lg md:text-xl lg:text-2xl font-medium text-gray-700 font-[Inter]"
                >
                  Last name
                </label>
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  autoComplete="family-name"
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-lg md:text-xl lg:text-2xl px-4 py-3 bg-white hover:border-gray-400 transition-colors"
                  required
                />
              </div>
            </div>

            <div>
              <label 
                htmlFor="email" 
                className="block text-lg md:text-xl lg:text-2xl font-medium text-gray-700 font-[Inter]"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-lg md:text-xl lg:text-2xl px-4 py-3 bg-white hover:border-gray-400 transition-colors"
                required
              />
            </div>

            <div>
              <label 
                htmlFor="phone" 
                className="block text-lg md:text-xl lg:text-2xl font-medium text-gray-700 font-[Inter]"
              >
                Phone Number
              </label>
              <input
                type="tel"
                name="phone"
                id="phone"
                autoComplete="tel"
                className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-lg md:text-xl lg:text-2xl px-4 py-3 bg-white hover:border-gray-400 transition-colors"
                required
              />
            </div>

            <div>
              <label 
                htmlFor="message" 
                className="block text-lg md:text-xl lg:text-2xl font-medium text-gray-700 font-[Inter]"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows={4}
                className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-lg md:text-xl lg:text-2xl px-4 py-3 bg-white hover:border-gray-400 transition-colors"
                required
              />
            </div>

            <div className="flex flex-col items-center space-y-4">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LflRZYqAAAAAKzI2NAVTZnvK_CI_oj9kb3NWNIH"
                size="normal"
              />
              
              {submitStatus === 'success' && (
                <div className="text-green-600 text-lg font-medium mb-4">
                  Thank you for your message! We'll get back to you soon.
                </div>
              )}
              
              {submitStatus === 'error' && (
                <div className="text-red-600 text-lg font-medium mb-4">
                  Something went wrong. Please try again.
                </div>
              )}

              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full flex justify-center py-3 px-6 border border-transparent rounded-md shadow-sm text-lg md:text-xl lg:text-2xl font-medium text-white ${
                  isSubmitting 
                    ? 'bg-indigo-400 cursor-not-allowed' 
                    : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                }`}
              >
                {isSubmitting ? 'Sending...' : 'Send message'}
              </button>
            </div>
          </form>
        </div>
      </motion.div>
    </section>
  );
};

export default Contact;
